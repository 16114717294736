import React from 'react';
import {Badge, Col, Row, Stack} from "react-bootstrap";
import Card from "react-bootstrap/Card";
import ImageViewModal from "../ImageViewModal";



function MediaLine({text, mediaLinks = [], md = 4, modal}) {
    const [modalShow, setModalShow] = React.useState(false);
    const [mediaLink, setMediaLink] = React.useState('');

    function renderHeader() {
        return text
            ? <div className='vov'>
                <Badge bg='primary'><h2>{text}</h2></Badge>
            </div>
            : '';
    }

    return (
        <>
            <Stack gap={3}>
                <Row>
                    <Col>
                        {renderHeader()}
                    </Col>
                </Row>
                <Row>
                    {mediaLinks.map((ml) =>
                        <Col key={ml} md={md}>
                            <Card>
                                <Card.Img variant="top" src={ml} onClick={() => {
                                    setMediaLink(ml)
                                    if (modal) setModalShow(true)
                                }}/>
                            </Card>
                        </Col>
                    )}
                </Row>
            </Stack>
            <ImageViewModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                mediaLink={mediaLink}
            />
        </>
    );
}

export default MediaLine;
