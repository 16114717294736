import React from 'react';
import {Alert, Col, Container, Row, Spinner, Stack} from "react-bootstrap";
import HomeCarousel from "../carousel/HomeCarousel";
import HitsCarousel from "../hit/ProductHits";
import {text_1, text_1_2_1, text_1_4} from "../../app/text_home";
import MediaLine from "../../components/kit/MediaLine";
import {useQuery} from "@apollo/client";
import {MEDIA_LINKS_QUERY, PRODUCT_QUERY} from "../../app/graphql";
import HeaderNavbar from "../navbar/header/HeaderNavbar";
import {text_1_0} from "../../app/text_basic";
import ServerCallAlert from "../../components/ServerCallAlert";

function HomePage() {

    const {loading, error, data} = useQuery(MEDIA_LINKS_QUERY, {
        variables: {mediaType: 'NEW'},
    });

    function renderFreshSection() {
        if (loading) return (
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        );

        if (error) return <ServerCallAlert message={text_1_0}/>

        return (
            <MediaLine md={4} text={text_1_4} mediaLinks={[...data.mediaLinks, ...data.mediaLinks]}/>
        )
    }

    return (
        <>
            <Row className="justify-content-md-center">
                <Col md={10}><h5>{text_1}</h5></Col>
            </Row>
            <hr/>
            <Row style={{marginBottom: '20px'}}>
                <HomeCarousel/>
            </Row>
            <hr/>
            <Row><h5>{text_1_2_1}</h5></Row>
            <Stack gap={3}>
                <Row>
                    {renderFreshSection()}
                </Row>
                <Row>
                    <HitsCarousel/>
                </Row>
            </Stack>
        </>
    );
}

export default HomePage;
