export const text_1 = 'Таблицы размеров';
export const text_2 = 'Описание полотен';

export const text_3 = 'Велюр — это шерстяная ворсованная ткань из пряжи суконного прядения, ворс которой чуть длиннее по сравнению с бархатом. По методу оформления ворса велюр может быть гладким, фасонным, тиснёным и других видов. Ворс при этом располагается по-разному: вертикально по всей ткани, на отдельных участках приглажен в одну сторону, с тиснением, с расчёсом по трафарету или укладкой в виде рисунка или узора.  В отличие от разрезного, «петельчатый» велюр — это трикотажное полотно, которое тянется и подходит для пошива одежды. Особенности: приятная на ощупь текстура, долговечность, простота в уходе.'
export const text_4 = 'Вискоза — материал, произведенный искусственным путем из натурального сырья (древесины). В зависимости от способа обработки может быть похожа на хлопок, лен, шелк или шерсть. Изготавливается глянцевой или матовой, фактурной или ровной, тяжелой или невесомой. От того, добавлен ли при производстве эластан, зависит, тянется или нет материя. Особенности: хорошо окрашивается, гигроскопична, довольно высокая степень прочности (в сухом виде).'
export const text_5 = 'Интерлок — материал из хлопка, относящийся к категории двойного трикотажа, т.к. при производстве применяются два ряда игл вязального станка. В результате применения такого метода материал имеет одинаковый внешний вид с обеих сторон, то есть он не имеет «лицевой» и «изнаночной» стороны. Особенности: не распускается при механическом повреждении полотна, хорошо сохраняет цвет и форму, легок в уходе. Состав: 100% хлопок.'
export const text_6 = 'Интерлок пенье – это материал, при изготовлении которого используются самые лучшие длинные нити. Особенности: идеально гладкий, тонкий, на ощупь немного шелковистый, довольно прочный,  практически не мнется, долго сохраняет форму, хорошо удерживает тепло и обеспечивает свободную циркуляцию воздуха. . Состав:  100% хлопок.'
export const text_7 = 'Кулирка с лайкрой — это обычная кулика с добавлением синтетического волокна, другое название стрейч-кулир. Лайкра лучше держит цвет, чем хлопок: изделия долго остаются яркими, не выгорают на солнце и не теряют цвет при многочисленных стирках. Особенности: эластичность, гипоаллергенность, гигроскопичность, возможна усадка при стирке. . Состав:  95%хлопок+ 5% эл.'
export const text_8 = 'Рибана — это натуральная хлопчатобумажная материя, представляющая из себя поперечно вязаный трикотаж с двойным переплетением волокон. Имеет одинаковый рисунок лицевой и изнаночной стороны. Чередование лицевых и изнаночных петель создает рельефную структуру мелкой «резинки». Особенности: прочность, износостойкость, эластичность, неприхотливость в уходе. . Состав: 100% хлопок.'
export const text_9 = 'Футер с лайкрой двухнитка — это футерная ткань с добавлением двух дополнительных нитей, в состав которой входит лайкра для улучшения свойств ткани. Особенности: эластичность, мягкость, гигроскопичность, легкость в уходе, комфорт при носке. . Состав:75% хлопок+20%п.э.+5% лайкра.'
export const text_10 = 'Футер 3х нитка   – это теплый вид трикотажа, лицевая сторона которого напоминает кулирную гладь, а изнаночная сторона – начес. Ткань хорошо растяжимая, прочная и износостойкая.	Состав:75%¬¬¬хлопок+20% п.э.+5% лайкра.'
